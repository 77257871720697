<template>
  <div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
    >
      <router-link
        to="/reputation/115"
        class="menu-link"
      >
        <el-menu-item index="2">
          115版本名望计算器
        </el-menu-item>
      </router-link>
      <router-link
        to="/suit"
        class="menu-link"
      >
        <el-menu-item index="1">
          115级套装属性
        </el-menu-item>
      </router-link>
      <!-- <router-link to="/reputation/110" class="menu-link">
        <el-menu-item index="3">
          110版本名望计算器（已淘汰）
        </el-menu-item>
      </router-link>
      <router-link to="/speed" class="menu-link">
        <el-menu-item index="4">
          攻速鞋计算器（已淘汰）
        </el-menu-item>
      </router-link> -->
      <!-- Add more menu items as needed -->
    </el-menu>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

// Route-to-Index Mapping
const routeToIndex = {
  '/quality': '1',
  '/reputation/115': '2',
  '/reputation/110': '3',
  '/speed': '4'
  // Add more route-to-index mappings as needed
}

// Computed property for activeIndex
const activeIndex = computed(() => routeToIndex[route.path] || '1')

// Watch for route changes to update activeIndex
watch(() => route.path, (newPath) => {
  console.log(newPath)
  // No need to manually update activeIndex, as it's computed
})
</script>

<style>
.menu-link {
  text-decoration: none; /* 去掉下划线 */
}
</style>
