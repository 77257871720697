import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    // component: () => import('@/views/index/index')
    redirect: { name: 'Reputation115' }  // Redirect to Reputation115
  },
  {
    path: '/suit',
    name: 'SuitIndex',
    component: () => import('@/views/suit/index')
  },
  {
    path: '/reputation/115',
    name: 'Reputation115',
    component: () => import('@/views/reputation/index')
  },
  // {
  //   path: '/reputation/110',
  //   name: 'Reputation110',
  //   component: () => import('@/views/reputation/index.vue')
  // },
  // {
  //   path: '/speed',
  //   name: 'SpeedIndex',
  //   component: () => import('@/views/speed/index')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
