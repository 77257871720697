<template>
  <div class="main">
    <el-header>
      <Navbar />
    </el-header>
    <router-view />
    <!--    <el-footer>-->
    <!--      <AppFooter />-->
    <!--    </el-footer>-->
  </div>
</template>

<script>
import Navbar from './components/Nav.vue'
// import AppFooter from './components/AppFooter.vue'

export default {
  name: 'App',
  components: {
     Navbar
    // AppFooter
  }
}
</script>
<style>
.main{
  padding:0;
}
</style>
